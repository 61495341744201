<template>
  <transition name="fade-block-wait">
    <div
      class="imp-faq-item"
      :class="{
        'imp-faq-item_mobile': isMobile
      }"
    >
      <div
        class="imp-faq-item-row"
        @click="clickByHeader"
      >
        <div>
          <PlusIcon
            v-if="!accordionState"
            :width="isMobile ? 20: 24"
          />
          <MinusIcon
            v-else
            :width="isMobile ? 20: 24"
          />
        </div>
        <div
          class="imp-faq-item-row__title"
          :class="{'imp-faq-item-row__title_mobile':isMobile}"
        >
          {{ item.title }}
        </div>
      </div>
      <transition name="fade-el">
        <div
          v-if="accordionState"
          class="imp-faq-item-row__content"
          :class="{'imp-faq-item-row__content_mobile':isMobile}"
        >
          <div v-html="item.description">
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>

import PlusIcon from '@/components/iconsTempalte/PlusIcon';
import MinusIcon from '@/components/iconsTempalte/MinusIcon';

export default {
  name: 'FaqItem',
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: ['item', 'isMobile'],
  data: () => ({
    accordionState: false,
  }),
  methods: {
    clickByHeader() {
      this.accordionState = !this.accordionState;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/sass/utils/variables';
.imp-faq-item {

  &__link {
    &:after {
      content: ' ';
      clear: right;
      display: block;
    }
  }

  a {
    color: $color-bright-turquoise !important;

    &:hover {
      opacity: 0.7;
      transition: 0.5s;
    }
  }

  p + p {
    margin-top: 15px;
  }

}

</style>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';
  .imp-faq-item {
    border-bottom: 1px solid $color-mine-shaft-light;
    padding-bottom: 26px;
    padding-top: 26px;

    &_mobile {
      padding-bottom: 17px;
      padding-top: 11px;
    }

    &:nth-last-child(-n+2) {
      border-bottom: 0;
    }

    &-row {
      display: flex;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        transition: 0.5s;
      }

      &__title {
        font: 20px $font-global-semibold;
        line-height: 28px;
        margin-left: 16px;
        margin-bottom: 10px;

        &_mobile {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
        }
      }

      &__content {
        margin-left: 40px;
        font: 18px $font-global;
        line-height: 30px;
        color: $color-ghost-second;

        &_mobile {
          font-size: 14px;
          line-height: 22px;
          margin-left: 37px;
        }
      }
    }
  }

</style>
