import { render, staticRenderFns } from "./MinusIcon.vue?vue&type=template&id=52e80236&scoped=true&"
import script from "./MinusIcon.vue?vue&type=script&lang=js&"
export * from "./MinusIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e80236",
  null
  
)

export default component.exports