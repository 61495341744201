<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.38281 18.6172C7.24219 20.4401 9.44792 21.3516 12 21.3516C14.5521 21.3516 16.7396 20.4401 18.5625 18.6172C20.4219 16.7578 21.3516 14.5521 21.3516 12C21.3516 9.44792 20.4219 7.26042 18.5625 5.4375C16.7396 3.57812 14.5521 2.64844 12 2.64844C9.44792 2.64844 7.24219 3.57812 5.38281 5.4375C3.5599 7.26042 2.64844 9.44792 2.64844 12C2.64844 14.5521 3.5599 16.7578 5.38281 18.6172ZM3.74219 3.79688C6.03906 1.5 8.79167 0.351562 12 0.351562C15.2083 0.351562 17.9427 1.5 20.2031 3.79688C22.5 6.05729 23.6484 8.79167 23.6484 12C23.6484 15.2083 22.5 17.9609 20.2031 20.2578C17.9427 22.5182 15.2083 23.6484 12 23.6484C8.79167 23.6484 6.03906 22.5182 3.74219 20.2578C1.48177 17.9609 0.351562 15.2083 0.351562 12C0.351562 8.79167 1.48177 6.05729 3.74219 3.79688ZM13.1484 6.14844V10.8516H17.8516V13.1484H13.1484V17.8516H10.8516V13.1484H6.14844V10.8516H10.8516V6.14844H13.1484Z"
      fill="#00CBF8"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: ['width'],
};
</script>

<style scoped>

</style>
